<template>
  <div class="option-selector selected-options-product selected-options-product__size">
    <div class="wrapp-size-select">
      <no-ssr>
        <v-select
        ref="sizeSelect"
        class="size-select select-shadowed underline-select form-control"
        :class="{'selected': selected, 'has-errors': sizeError}"
        v-model="selected"
        label="label"
        :options="options"
        :placeholder="((!$store.state.ui.isMobile) ? ($t('size') + ':') : '-')"
        :clearable="false"
        :searchable="false"
        :selectable="selectableOption"
        @input="change($event, fromInput = true)">
          <template #open-indicator="{attributes}">
            <span v-bind="attributes">
              <i class="icomoon-icon icon-chevron-down arrow-indicator" />
            </span>
          </template>
          <template #search="{ attributes, events }">
            <input maxlength="1" class="vs__search" v-bind="attributes" v-on="events">
            <div class="placeholder">{{((!$store.state.ui.isMobile) ? ($t('size') + ':') : 'Seleziona')}}</div>
          </template>
          <!-- if they want us to write out of stock under the taglia we can uncomment this part -->
          <!-- <template slot="selected-option" slot-scope="opt">
            <div class="wrapp-option">
              <span class="option-label" :class="{'out-of-stock':  opt.quantity <= 0}">
                {{opt.label}}
              </span>
              <div v-if="outOfStock=opt.quantity <= 0">
                <span class="wishlist-out-of-stock d-none d-md-inline">{{$t('OUT_OF_STOCK')}}</span>
              </div>
              <span class="wishlist-out-of-stock d-none d-md-inline" v-if="!opt.in_stock">{{$t('OUT_OF_STOCK')}}</span>
            </div>
          </template> -->
          <template #option="opt" :deselect="true">
            <div class="wrapp-option" :class="{'out-of-stock': opt.quantity <= 0}">
              <span class="option-label pagebuilder-button-link" :class="{'out-of-stock': opt.quantity <= 0}">{{opt.label}}</span>
              <size-selector-extra-label
                class="extra-label"
                :isBackInStock="opt.is_backinstock === 1"
                :onlyOneLeft="opt.quantity === 1"
                :outOfStock="opt.quantity <= 0"
                :isPreOrder="opt.is_pre_order"
                :showLabelBackInStock="true">
              </size-selector-extra-label>
            </div>
          </template>
        </v-select>
      </no-ssr>
      <p class="errors-message is-invalid text-right mt-2" v-t="'size-select-error'" v-show="sizeError"></p>
    </div>
    <ModalSizeGuide v-if="modalSizeGuide.open" v-model="modalSizeGuide.open" :product="sizeGuideProduct" v-on:close="toggleSizeGuide({open: false, activeTab: 0})" />
  </div>
</template>

<script>
import ModalSizeGuide from 'theme/components/Modal/SizeGuide'
import SizeSelector from '@/components/Product/SelectedOptions/SizeSelector'
import SizeSelectorExtraLabel from 'theme/components/Product/Variants/SizeSelectorExtraLabel'
import SizeSelectorTranslate from 'theme/components/Product/Variants/SizeSelectorTranslate'

export default {
  mixins: [SizeSelector],
  data () {
    return {
      modalSizeGuide: {
        open: false,
        activeTab: 0
      }
    }
  },
  computed: {
    sizeGuideProduct () {
      // prepare product for size guide
      if (this.product && this.product.presetFilter) {
        let color = Object.values(this.product.presetFilter)[0]
        let product = this.product.groupedVariation.find(prd => prd.color === color)
        if (product) {
          return product
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    getFilterCompose (newValues) {
      let notifiable = newValues ? (!newValues.in_stock && newValues.is_backinstock === 1) : false
      return { type: this.parentAttribute, value: newValues ? newValues.value_index : null, isNotificable: notifiable }
    },
    toggleSizeGuide (data) {
      this.modalSizeGuide = {
        open: data.open,
        activeTab: data.activeTab
      }
    },
    showError (payload) {
      this.sizeError = payload
    }
  },
  components: {
    ModalSizeGuide,
    SizeSelectorExtraLabel,
    SizeSelectorTranslate
  }
}
</script>
