<template>
  <div class="product-info__color">
    <no-ssr>
      <v-select
      ref="colorSelect"
      class="color-select form-control select-shadowed underline-select w-100"
      v-model="selected"
      label="label"
      :options="options"
      :clearable="false"
      :searchable="false"
      @input="change($event, fromInput = true)"
      :placeholder="$t('Color')">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <i class="icomoon-icon icon-chevron-down arrow-indicator" />
          </span>
        </template>
<!--         <template #search="{ attributes, events }">
          <input maxlength="1" class="vs__search" v-bind="attributes" v-on="events">
          <div class="placeholder">{{$t('color')}}</div>
        </template> -->
        <template slot="selected-option" slot-scope="opt">
          <span class="color-value value" :style="getColorSwatch(opt)"></span>
          <div class="wrapp-option">
            <span class="option-label-color">{{getColorLabel(opt)}}</span>
            <div class="placeholder-color">{{$t('color')}}</div>
          </div>
        </template>
        <template #option="opt">
          <div class="wrapp-option">
            <span class="color-value value" :style="getColorSwatch(opt)"></span>
            <span class="option-label">{{getColorLabel(opt)}}</span>
          </div>
        </template>
      </v-select>
    </no-ssr>
  </div>
</template>

<script>
import ColorSelector from '@/components/Product/SelectedOptions/ColorSelector'

export default {
  mixins: [ColorSelector],
  methods: {
    getColorSwatch (colorSwatch) {
      let res
      if (colorSwatch.swatch_data && colorSwatch.swatch_data.value) {
        res = colorSwatch.swatch_data.value.startsWith('#')
          ? `background: ${colorSwatch.swatch_data.value}`
          : `background: center / contain url(${this.createBackgroundUrlSwatch(colorSwatch.swatch_data.value)})`
      } else {
        let valueIndex = colorSwatch.value_index
        let groupedSelected = this.item.groupedVariation.find(item => item.color === valueIndex)
        res = groupedSelected.color_image.includes('#')
          ? `background: #${groupedSelected.color_image.split('#')[1]}`
          : `background: center / contain url('${groupedSelected.color_image}')`
      }
      return res
    },
    getColorLabel (colorSwatch) {
      if (colorSwatch.swatch_data && colorSwatch.swatch_data.value) {
        return colorSwatch.label
      } else {
        let valueIndex = colorSwatch.value_index
        let groupedSelected = this.item.groupedVariation.find(item => item.color === valueIndex)
        return groupedSelected.color_label
      }
    },
    createBackgroundUrlSwatch (urlValue) {
      // here we create a static link for color swatch image (magento don't provide absolute path)
      return this.$store.state.storeConfig.host + '/media/attribute/swatch' + urlValue
    }
  }
}
</script>
