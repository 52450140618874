<template>
    <span v-html="extraInfo"></span>
</template>

<script>
export default {
  props: {
    onlyOneLeft: {
      type: Boolean,
      required: false
    },
    outOfStock: {
      type: Boolean,
      required: false
    },
    isPreOrder: {
      type: Boolean,
      required: false
    },
    isBackInStock: {
      type: Boolean,
      default: false
    },
    isComingSoon: {
      type: Boolean,
      default: false
    },
    showLabelBackInStock: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    extraInfo () {
      if (this.outOfStock && this.isBackInStock) {
        if (this.showLabelBackInStock) {
          return '<span class="classic-action disabled"> - ' + this.$t('productPage.backInStock') + '</span>'
        }
        return ''
      } else if (this.outOfStock) {
        return '<span class="classic-action disabled"> - ' + this.$t('OUT_OF_STOCK') + '</span>'
      } else {
        return ''
      }
    }
  }
}
</script>
