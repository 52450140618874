<template>
  <div class="product-info__color w-100">
    <no-ssr>
      <v-select
      ref="colorSelect"
      class="color-select select-shadowed underline-select w-100"
      v-model="selected"
      label="label"
      :options="options"
      :clearable="false"
      :searchable="false"
      @input="change($event, fromSelect = true)"
      :placeholder="$t('color')">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <span class="arrow-down-end"></span>
          </span>
        </template>
        <template slot="selected-option" slot-scope="opt">
          <div class="wrapp-option">
            <span class="value" :style="{backgroundImage: `url(${opt.urlResize})`}"></span>
            <span class="option-label">{{opt.label}}</span>
          </div>
        </template>
        <template #option="opt">
          <div class="wrapp-option">
            <span class="value" :style="{backgroundImage: `url(${opt.urlResize})`}"></span>
            <span class="option-label">{{opt.label}}</span>
          </div>
        </template>
      </v-select>
    </no-ssr>
  </div>
</template>

<script>
import find from 'lodash/find'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    parentAttribute: {
      type: String,
      required: true
    },
    defaultColorSelected: {
      type: String | Number,
      required: false,
      default: null
    },
    filterCode: {
      type: String,
      default: 'size'
    }
  },
  data () {
    return {
      selected: null
    }
  },
  mounted () {
    if (this.defaultColorSelected) {
      this.selected = find(this.options, { value_index: this.defaultColorSelected })
      this.change(this.selected)
    }
  },
  watch: {
    defaultColorSelected: {
      handler: function (newValue) {
        this.setColor({
          options: this.options,
          value: newValue
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setColor (event) {
      const _this = this
      if (event && event.value && event.options) {
        _this.selected = find(event.options, { value_index: event.value })
        _this.change(_this.selected)
      }
    },
    change (newValues, fromSelect) {
      // fromSelect is a discriminatory for change value from select
      if (fromSelect) {
        // if from select emit changeColor event
        this.$emit('changeColor')
      }
      let filterCompose = this.getFilterCompose(newValues)
      this.$emit('filterSelected', filterCompose)
      if (fromSelect) {
        this.$emit('updateproductwishlist', { attribute_code: this.parentAttribute, option: newValues })
      }
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues.value_index }
    }
  }
}
</script>
