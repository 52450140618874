<template>
  <div class="selected-options-product selected-options-product__size">
    <div class="wrapp-size-select">
      <no-ssr>
        <v-select
        ref="sizeSelect"
        class="size-select select-shadowed underline-select"
        :class="{'has-errors': sizeError}"
        v-model="selected"
        label="label"
        :options="options"
        :clearable="false"
        :searchable="false"
        :selectable="selectableOption"
        @input="change($event, fromSelect = true)"
        :placeholder="$t('size')">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <span class="arrow-down-end"></span>
            </span>
          </template>
          <template slot="selected-option" slot-scope="opt">
            <div class="wrapp-option">
              <span class="option-label">
                {{opt.label}} {{getActiveCountry}}
              </span>
            </div>
          </template>
          <template #option="opt" :deselect="true">
            <div class="wrapp-option" :class="{'out-of-stock': !opt.in_stock}">
              <span class="option-label">{{opt.label}} {{getActiveCountry}}</span>
            </div>
          </template>
        </v-select>
      </no-ssr>
      <p class="errors-message is-invalid text-right mt-2" v-t="'size-select-error'" v-show="sizeError"></p>
    </div>
  </div>
</template>

<script>
import { EventBus, getVariantByFilter } from '@/helpers'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSizeSelected: {
      type: Number,
      required: false,
      default: null
    },
    filterCode: {
      type: String,
      default: 'size'
    }
  },
  data () {
    return {
      sizeError: false,
      hoverChoice: null,
      selected: null,
      modalSizeGuide: {
        open: false,
        activeTab: 0
      }
    }
  },
  computed: {
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    selectableOutOfStock () {
      return this.$store.state.product.selSizeProductOutOfStock
    },
    optionsByFilter () {
      let filters
      if (this.product) {
        filters = getVariantByFilter(this.filterCode, this.filterActive, this.product)
      } else {
        filters = this.$store.getters['product/getVariantByFilter'](this.filterCode, this.filterActive)
      }
      return filters && filters.length ? sortBy(filters, ['value']) : []
    }
  },
  methods: {
    resetSize () {
      return this.change(null)
    },
    selectableOption (option) {
      return option.in_stock || (!option.in_stock && option.is_backinstock === 1)
    },
    calcClassName (opt) {
      let className = {
        'active': this.selected && this.selected.value === opt.value,
        'out-of-stock': !opt.in_stock,
        'unselectableOptions': false
      }
      return className
    },
    showError (payload) {
      if (!this.selected) {
        this.sizeError = payload
      }
    },
    listenerEventChange (payload) {
      this.$nextTick(function () {
        if (this.selected && this.selected.value) {
          let matchArr = find(this.optionsByFilter, ['value_index', this.selected.value])
          if (matchArr && !matchArr.in_stock) {
            this.selected = null
            return this.change({ value: this.selected })
          }
        }
      })
    },
    eventChangeFromRemove (newValue) {
      // try to find on new option with old value selected
      let matchArr = find(this.options, ['value_index', newValue])
      this.selected = matchArr
      if (matchArr) {
        return this.change(matchArr)
      } else {
        return this.change(null)
      }
    },
    change (newValues, fromSelect) {
      this.selected = newValues
      this.showError(false)
      this.$emit('filterSelected', this.getFilterCompose(newValues))
      // fromSelect is a discriminatory for change value from select
      // if from select emit resetSize event
      if (process.client) {
        window.naizjs && window.naizjs.naiz_recommend_size && window.naizjs.naiz_recommend_size()
      }
      if (fromSelect) {
        this.$emit('updateproductwishlist', { attribute_code: this.parentAttribute, option: newValues })
      }
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues ? newValues.value_index : null }
    }
  },
  mounted () {
    // EventBus.$on('product:filterChange', this.listenerEventChange)
    EventBus.$on('cart:sizeError', this.showError)
    // if (this.defaultSizeSelected) {
    //   this.selected = find(this.options, { value_index: this.defaultSizeSelected })
    //   return this.listenerEventChange({ type: this.filterCode })
    // }
  },
  destroyed () {
    EventBus.$off('cart:sizeError', this.showError)
    EventBus.$off('product:filterChange', this.listenerEventChange)
  },
  watch: {
    defaultSizeSelected: {
      handler: function (newValue) {
        this.eventChangeFromRemove(newValue)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
